import React from 'react'
const ContentsList = ({ items, depth }) => {
  return (
    <ul className={depth ? 'pl-3 space-y-3' : 'space-y-5 text-sm text-gray-500 font-medium'}>
      {items.map((item) => {
        return <ContentsItem key={`${item.url}`} item={item} depth={false} />
      })}
    </ul>
  )
}

const ContentsItem = ({ item }) => (
  <li className={`space-y-3`}>
    <a
      className={`block text-gray-700 hover:text-gray-500 hover:underline transition ease-in-out duration-150`}
      href={item.url}
    >
      {item.title}
    </a>

    {/**
     * conditionally render another `ContentsList` within this `<li>`
     * if there is a `items` array within this `item`
     */}
    {item.items && item.items.length && (
      <ContentsList key={`${item.url}`} items={item.items} depth={true} />
    )}
  </li>
)

export default ContentsList
