import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ContentsList from '../components/ContentsList'
import { Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Sites from '../components/Sites'

const Post = ({ data, pageContext }) => {
  const { mdx } = data // data.markdownRemark holds your post data
  const { frontmatter, slug, body, tableOfContents } = mdx
  const { next, previous } = pageContext
  const hashtags = frontmatter.hashtags
  return (
    <Layout>
      <Seo
        title={frontmatter.title}
        description={frontmatter.description}
        url={`https://gubelinlab.com/${slug}/`}
        image={`https://gubelinlab/images/${slug}.webp`}
      />
      <div className="py-12 xl:pt-6 xl:pb-6">
        <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 xl:grid xl:grid-cols-3">
          <div className="pt-12 xl:pb-10 col-span-3">
            <div className="space-y-1 text-center">
              <dl className="space-y-10">
                <div>
                  <dt className="sr-only">Published on</dt>
                  <dd className="text-base leading-6 font-medium text-gray-500">
                    <time dateTime={frontmatter.updated_at}>{frontmatter.created_at}</time>
                  </dd>
                </div>
              </dl>
              <div>
                <h1 className="mt-2 text-3xl leading-8 font-display font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  {frontmatter.title}
                </h1>
              </div>
            </div>
          </div>
          <div className="xl:col-span-2 xl:pr-8 xl:border-r xl:border-gray-200">
            <div className="pt-10 pb-8">
              <figure>
                <div className={`aspect-w-16 aspect-h-9`}>
                  <img
                    title={frontmatter.title}
                    alt={frontmatter.title}
                    src={`data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=`}
                    srcSet={`data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=`}
                    data-src={`https://ik.imagekit.io/gubelinlab/tr:w-1200,h-675,f-auto/${slug}.webp 1200w`}
                    data-srcset={`https://ik.imagekit.io/gubelinlab/tr:w-300,h-169,f-auto/${slug}.webp 300w,https://ik.imagekit.io/gubelinlab/tr:w-600,h-338,f-auto/${slug}.webp 600w,https://ik.imagekit.io/gubelinlab/tr:w-800,h-450,f-auto/${slug}.webp 800w,https://ik.imagekit.io/gubelinlab/tr:w-1200,h-675,f-auto/${slug}.webp 1200w`}
                    loading="lazy"
                    className="rounded-xl shadow-lg blur-up lazyload"
                  />
                </div>
                <figcaption className="flex mt-3 text-base text-gray-500">
                  <svg
                    className="flex-none w-5 h-5 mr-2 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                  <span>{frontmatter.title}</span>
                </figcaption>
              </figure>
            </div>
            <div className="mt-8 xl:hidden">
              <h2 className="text-gray-900 uppercase tracking-wide font-semibold mb-3 text-sm">
                Daftar Isi
              </h2>
              <aside className={`space-y-5`}>
                {tableOfContents.items && (
                  <ContentsList key={tableOfContents.items.url} items={tableOfContents.items} />
                )}
              </aside>
            </div>
            <div className="prose prose-rose prose-lg xl:prose-xl max-w-none py-8">
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </div>
          <aside className="hidden xl:block xl:pl-8">
            <div className="sticky top-20 divide-y divide-gray-200">
              <div className="mb-8">
                <h2 className="text-gray-900 uppercase tracking-wide font-semibold mb-3 text-sm">
                  Daftar Isi
                </h2>
                <div className={`space-y-5`}>
                  {tableOfContents.items && (
                    <ContentsList key={tableOfContents.items.url} items={tableOfContents.items} />
                  )}
                </div>
              </div>
              {(next || previous) && (
                <div className="space-y-8 py-8">
                  {next && (
                    <div>
                      <h2 className="text-sm font-bold tracking-wide uppercase text-gray-500">
                        Artikel Selanjutnya →
                      </h2>
                      <div className="mt-3 text-rose-500 hover:text-rose-600">
                        <Link to={next.fields.slug}>{next.frontmatter.title}</Link>
                      </div>
                    </div>
                  )}
                  {previous && (
                    <div>
                      <h2 className="text-sm font-bold tracking-wide uppercase text-gray-500">
                        ← Artikel Sebelumnya
                      </h2>
                      <div className="mt-3 text-rose-500 hover:text-rose-600">
                        <Link to={previous.fields.slug}>{previous.frontmatter.title}</Link>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div className="space-y-8 space-x-2 py-8">
                <h2 className="text-sm font-medium text-gray-500"># Hashtags</h2>
                <ul className="mt-2 leading-8 space-x-2">
                  {hashtags.map(function (item, i) {
                    return (
                      <li key={i} className="inline">
                        <div className="relative inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5">
                          <div className="absolute flex-shrink-0 flex items-center justify-center">
                            <svg
                              className="h-3 w-3"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14"
                              />
                            </svg>
                          </div>
                          <div className="ml-3.5 text-sm font-medium text-gray-900">
                            {item.toUpperCase()}
                          </div>
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </aside>
        </div>
      </div>
      <Sites />
    </Layout>
  )
}

export default Post

export const PostQuery = graphql`
  query ($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        title
        hashtags
        description
        created_at(formatString: "dddd, DD MMMM YYYY", locale: "id")
        updated_at
      }
      slug
      body
      tableOfContents
    }
  }
`
